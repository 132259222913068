export const routes = {
  users: "/users",
  branch: "/branches",
  plan: "/plans",
  register: "/auth/local/register",
  subscription: "/subscriptions",
  bodyDetail: "/body-details",
  ptp: "/personal-training-programs",
  event: "/events",
  payment: "/payments",
  invoice: "/invoices",
  walkin: "/walk-ins",
  alert: "/alerts",
  "body-tracking": "/body-trackings",
};
